body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 0.85rem !important;
}

.headerToolbarTabs a {
  color: #676767 !important;
}

.dlg-container.dlg-show {
  position: absolute;
  min-width: 100vw;
  margin-left: -230px;
  margin-top: -138px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


div[class^='RaLogin-main'], div[class*=' RaLogin-main']{
  background-image: radial-gradient(circle at 50% 14em, rgb(61 175 44) 0%, rgb(44 87 66) 60%, rgb(84 87 90) 100%);
}


.logo {
  padding: 10px;
  position: absolute;
  top: -90px;
}

.logo-img {
  height: 5.5vh;
  margin-left: 2vh;
  margin-top: 1vh;
}


@font-face {
  font-family: 'Nexa';
  src: local('Nexa'), url(./fonts/Nexa-Light.otf) format('opentype');
}

@font-face {
    font-family: 'Nexa Bold';
    font-weight: 900;
    src: local('Nexa'), url(./fonts/Nexa-Bold.otf) format('opentype');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiAppBar-colorSecondary {
  background-color: #bd392f !important;
}

.RaSidebar-drawerPaper-23 {
  background-color: #e0e0e0 !important;
}

.banner img {
  width: 100%;
  height: 94px;
}

main {
  margin-top: 140px;
}

.MuiButton-textPrimary {
  background-color: #5c9d66 !important;
  color: white !important;
}

.MuiTablePagination-root .MuiButton-textPrimary {
  background-color: #ffffff !important;
  color: black !important;
}

.boton-blanco {
  color: white !important;
}

.MuiDrawer-docked {
  flex: 0 0 auto;
  background-color: #e0e0e0;
}

.RaButton-button-54 {
  border: 1px solid !important;
  padding: 5px !important;
  margin-left: 10px !important;
}

.MuiTableCell-head {
  color: rgb(255 255 255) !important;
  background-color: #319B42 ;
}

.MuiTableRow-head{
  color: rgb(255 255 255) !important;
  background-color: #5c9d66  !important;
}

.MuiTableRow-head th{
  color: rgb(255 255 255) !important;
  background-color: #5c9d66  !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: rgb(255 255 255) !important;
}

.MuiPaper-rounded {
  border: 1px solid #e9e9e9;
  height: auto;
  border-radius: 10px !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #54585A !important;
}

.simple-form .MuiButton-textPrimary {
  color: #f9f9f9 !important;
  background-color: #319B42;
  padding: 8px 10px !important;
}

.simple-form .MuiButton-textPrimary.ra-delete-button {
  background-color: #54585A !important;
}


.list-page .MuiButton-textPrimary.btn_add_detalle {
  color: #ffffff !important;
  background-color: #193e73 !important;
  margin-left: 10px !important;
  padding: 6px 10px !important;
}

.list-page .MuiTablePagination-root .MuiButton-textPrimary {
  color: black !important;
  background-color: white !important;
  margin-left: 0px !important;
  padding: 6px 8px !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #54585A !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #54585A !important;
}

.MuiCheckbox-colorSecondary.Mui-checked.checkBlanco {
  color: white !important;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #5c9d66 !important;
}
.dashCard {
    display: flex;
    padding: 16px;
    overflow: inherit;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    min-height: 52px;
}


.MuiGrid-spacing-xs-2 {
  width: calc(100% + 16px) !important;
  margin: -8px;
}


.MuiSnackbarContent-message{
    font-size: large !important;
    padding: 20px !important;
}

.MuiSnackbar-anchorOriginBottomCenter {
  top: 0px !important;
}

.MuiDrawer-docked {
  flex: 0 0 auto;
  background-color: #ffffff;
  border-right: 1px solid #e9e9e9;
}

.RaLayout-appFrame-5 {
  margin-top: 0px !important;
}


.divFlex {
    display: flex;
    margin-top: 10px;
}

.ra-confirm {
  color: #54585A !important;
}


table.tableValores td {
  padding: 5px;
  text-align: center;
}

.MuiDialog-paperWidthSm {
  max-width: 80% !important;
}

.grider .MuiCard-root {
  overflow: auto;
}

.MuiToolbar-dense {
  min-height: 48px;
  background-color: #ededed;
  color: #6c6c6c;
  border-bottom: 1px solid #e9e9e9;
}

.MuiDrawer-paperAnchorRight {
  width: 30%;
}

.MuiDrawer-paperAnchorRight .MuiFormControl-root {
  width: 100% !important;
}

div[class*='RaFormInput-input'] {
  width: 100% !important;
}

.MuiDrawer-paperAnchorRight .MuiFormControl-marginDense {
  margin-top: 0px;
  margin-bottom: 0px;
}

.MuiDrawer-paperAnchorRight .MuiCard-root {
  margin-top: -1em;
}

.MuiDialog-paperScrollPaper {
  width: auto;
}

.MuiDialogTitle-root {
  background-color: #319B42 !important;
  color: white;
  padding: 5px 24px;
}

.MuiDialogContentText-root {
  margin-bottom: 12px;
  margin-top: 15px;
}

.MuiButton-root {
  /*border: 1.5px solid #319B42 !important;*/
}

.MuiInputBase-input {
  font-size: 1em;
}

.MuiTypography-root {
  font-size: 1em !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #319B42 !important;
}

.rowNoMessage p.MuiFormHelperText-root {
  display: none;
}


.MuiButton-root {
  min-width: 30px !important;
}

.customDataGrid .MuiCard-root {
  overflow: auto;
}

.layout:nth-child(1) {
  margin-top: -48px !important;
}


.MuiDrawer-paper div[class*="RaSidebar-fixed"] {
  position: initial !important;
}

.customDataGrid .MuiFormHelperText-root {
  display: none;
}

.MuiTableCell-head {
  line-height: 1;
}

td.MuiTableCell-root {
  padding: 10px;
}

.titleClass {
  padding: 10px;
  font-weight: 600;
  color: #ffffff;
  background-color: #535759;
  margin-bottom: 10px;
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters button {
  padding: 3px !important;
  font-size: 1em;
  display: inline-flex;
  margin: 5px;
}

button[class*="RaPaginationActions-currentPageButton"]{
  color: white;
  background-color: #5c9d66;
}

.orderCustom {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.orderCustom:hover {
  text-decoration: underline;
  color: white;
}


.MuiFab-primary {
  color: #fff;
  background-color: #36a636 !important;
}

.list-page {
  max-width: 100% !important;
}

.MuiTableCell-root {
  display: table-cell;
  padding: 0.5vw;
  font-size: 0.8vw;
}

.MuiTable-root {
  width: 100%;
  display: inline-block;
  border-spacing: 0;
  border-collapse: collapse;
  overflow: auto;
  max-width: 100%;
}

/*RESPONSIVE */

@media (max-width: 1024px) {

  .col-md-custom {
    width: 100% !important;
  }
  
}


@media (max-width: 599.95px) {

  .MuiDrawer-paperAnchorRight {
    width: auto;
  }

  .MuiIconButton-colorPrimary {
      color: #36a636 !important;
  }

  .list-page .MuiToolbar-gutters {
      position: absolute;
  }

  .MuiTablePagination-toolbar {
      width: 100%;
  }

  .col-md-custom {
    width: calc(100vw - 30px) !important;
  }

  .MuiButton-root {
    border: none !important;
}

}

#main-content {
  max-height: calc(100vh - 143px);
  overflow-y: auto;
  overflow-x: auto;
  padding: 10px;
}

html {
  font-size: 0.85rem !important;
  overflow-y: hidden !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}



.MuiPaper-root {
    color: rgb(106 106 106) !important;
}

.MuiCardContent-root {
  max-width: 85vw !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #899e8c; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5c9d66; 
}

.MuiTableCell-head {
  line-height: 0.5rem !important;
}

.small-box {
    border-radius: 0.25rem;
    box-shadow: 0 0 1px rgb(117 125 133), 1px 3px 4px rgb(24 118 39 / 58%);
    display: block;
    margin-bottom: 20px;
    position: relative;
}

.small-box>.inner {
  padding: 10px;
}

.small-box .icon {
    position: absolute;
    right: 52px;
    top: -10px;
    transition: all .3s linear;
    color: rgba(0,0,0,.15);
    z-index: 0;
    font-size: 70px;
}


.PdfHighlighter {
  width: initial !important;
  height: calc(80vh - 20px) !important;
}

.rowAnalizer .MuiCardContent-root {
  padding-top: 0px !important;
}

#valorBusqueda-helper-text {
  display: none;
}

.rowAnalizer .MuiButtonBase-root {
  float: right;
}

div[class*="RaNotification-error"] {
  color: white !important;
}

.MuiSnackbarContent-message {
  color: white !important;
}

.dlg-container.dlg-show {
  margin-top: 0 !important;
  margin-left: 0 !important;
}

.ann-tree-details {
  display: none !important;
}

.ann-tree-controls {
  display: none !important;
}

.ann-tree-view {
  display: none;
}