
body .login_div {
    font-family: 'Nexa';
}

html {
    max-width: 100vw;
    overflow-x: hidden;
}

.login_div .enunciado {
    text-align: center;
    position: relative;
    height: 70vh;

}

.login_div input {
    border: 1px solid lightgray;
    border-radius: 6px;
}

.login_div .enunciado_texto{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    color: white;
    font-family: 'Nexa';
    font-size: 25px;
    font-weight: bold;
}

.login_div .row {
    margin: 0px !important;
}

.login_div a {
    color: black !important;
    text-decoration: none !important;
}

.login_div .content_login {
    background-color: #e4f6e8;
    width: 60%;
    padding: 8%;
    border-radius: 20px;
    border: 1px solid grey;
    top: 45%;
    position: absolute;
    transform: translateY(-50%);
    font-size: 15px;
    left: 10%;
}

.login_div .content_login div {
    /*
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: bold;
    width: 100%;
    */
}

.login_div input[type="submit"] {
    background-color: #4cac50;
    color: white;
    padding: 5px 50px;
    border-radius: 10px;
    border: 1px solid darkgreen;
}

.login_div .btn_pass {
    background-color: #4cac50;
    color: white;
    padding: 5px 50px;
    border-radius: 10px;
    border: 1px solid darkgreen;
    font-family: 'NEXA';
}

.login_div .password_recovery {
    padding: 10px;
    font-size: 14px;
}

.login_div .password_recovery a {
    color: #8d8d8d !important;
}

.image_login {
    height: 100%;
    width: auto;
    padding: 20px;
    padding-left: 60px;
}

.copyright {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    width: 80%;
    text-align: center;
}

@media (max-width: 600px) {

    .login_div .enunciado {
        text-align: center;
        position: relative;
        height: 28vh;
      }
    
      .login_div .content_login {
        background-color: #e4f6e8;
        width: 100%;
        padding: 40px;
        border-radius: 20px;
        border: 1px solid grey;
        top: 50%;
        position: relative;
        transform: translateY(-65%);
        font-size: 15px;
        margin-left: -10%;
      }

      .login_div input {
        border: 1px solid lightgray;
        border-radius: 6px;
        width: 100%;
    }

    .login_div .enunciado_texto {
        position: absolute;
        top: 65%;
        transform: translateY(-50%);
        width: 100%;
        color: white;
        font-family: 'Nexa';
        font-size: 15px;
        font-weight: bold;
    }

    .copyright {
        position: relative;
        transform: translateY(-50%);
        top: 30%;
        width: auto;
        text-align: center;
    }

}